<template>
<div id="asp">
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="18" :xl="15">
      <PageHeader/>

      <DepartmentAside aside-color="#dd92cb" title="高山病予防">
        <div class="img">
          <el-image :src="require(`@/assets/departments/asp.png`)"/>
        </div>
        <div class="detail1">
          <div class="title0">高山病とは？</div>
          <div class="content1">
            <div class="text1">高度が上がれば空気中の酸素が薄くなります。高所(2000メートル以上)は低酸素による影響が出ます。高齢者の方は1500メートル以上を高所と考えたほうがよいという意見もあります。高山病とは酸素の不足が身体にもたらす諸症状の総称です。高山病には大きく分けて次の3つがあります。</div>
          </div>
          <div class="content1">
            <ol>
              <li>
                <div class="text1">山酔い(Acute Mountain Sickness) </div>
                <div class="text1">頭痛に加え胃腸症状（食欲不振・吐気・嘔吐）、全身倦怠感又は全身脱力感、めまい又は立ちくらみ、睡眠障害のうちいずれかが1つ以上あります。</div>
              </li>
              <li>
                <div class="text1">高所肺水腫</div>
                <div class="text1">安静時の呼吸困難、咳、全身脱力感または歩行困難、胸部の圧迫感または詰まった感じのうちの2つ以上の症状があり、肺の呼吸音異常、チアノーゼ、呼吸回数の増加、心拍数の増加の2つ以上がある場合をいいます。一刻も早く高度を下げて治療を 　開始することが不可欠です。</div>
              </li>
              <li>
                <div class="text1">高所脳浮腫</div>
                <div class="text1">瀕死の状態と考えられます。山酔いの症状に加え、精神的におかしくなり（錯乱、昏睡など）、運動失調が出現します。一刻も早く高度を下げ、医療機関での治療を受けることが不可欠です。</div>
              </li>
            </ol>
          </div>
          <div class="content1">
            <div class="title1">治療</div>
            <div class="text1">当院では高山病予防薬としてダイアモックスというお薬を処方いたします。ダイアモックスは、日本では眼科の緑内障の治療薬ですが、内科領域ではなじみが薄く高山病予防薬としての使用が進んでいません。しかし、欧米の旅行者には一般化しているお薬です。但し、ダイアモックスは保険適応外ので、自費診療であることをご了承下さい。</div>
          </div>
          <div class="content1">
            <div class="text1">ダイアモックスが高山病に効く理由は、①脳血管を拡張し、血液量を増やし、脳の酸素不足を改善する、②呼吸中枢を刺激し、全身の低酸素状態を改善する、の2点であるといわれています。高地での旅行を満喫するためにも、高地へ行かれる際にはダイアモックスでの治療を是非ともご検討下さい。</div>
          </div>
          <div class="content1">
            <div class="text1">ダイアモックスの処方料（院外薬局宛の処方箋発行も含む）：3000円（税込）</div>
            <div class="text1">※別途調剤薬局での2000～3000円程度の調剤料・薬剤費がかかります。</div>
          </div>

        </div>
      </DepartmentAside>

    </el-col>
  </el-row>

  <PageFooter/>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
export default {
  name: "Asp",
  components: {DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
#asp {
  border-top: 10px solid #1796c4;
}

#asp /deep/ .content .title {
  letter-spacing: 0;
}

.detail1 {
  font-family: "ヒラギノ丸ゴProN W4", "Hiragino Maru Gothic ProN", "HG丸ｺﾞｼｯｸM-PRO", HGMaruGothicMPRO, "メイリオ", Meiryo, Osaka, "MS Pゴシック", "MS PGothic", sans-serif;
  margin: 40px 10px;
}
.title0 {
  font-size: 20px;
  font-weight: bold;
  color: #DD92CB;
  margin-bottom: 2rem;
}
.content1 {
  line-height: 1.5;
  margin-bottom: 2rem;
}
.title1 {
  color: #C34668;
}
.text1 {
  color: #333;
}

ol {
  padding-left: 20px;

  list-style-type: decimal;
  color: #333;
}

</style>
